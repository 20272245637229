import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  TextField,
  Avatar,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ModeloGetUrlJson } from "../ModeloCrud";
import * as moment from "moment";
import FacebookCircularProgress from "../FacebookCircularProgress";

// Registra los elementos y plugins
ChartJS.register(ArcElement, Legend, Title, ChartDataLabels);

// Estilos para el modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const loadingStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
};

const PieChartTurnosPorDia = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  const [odontologos, setOdontologos] = useState([]);
  const [totalTurnosEspecialidad, setTotalTurnosEspecialidad] = useState(0); // Cantidad total de turnos de la especialidad
  const [selectedClinica, setSelectedClinica] = useState(null); // Clínica seleccionada
  const today = "2024-03-08";

  // Gama de colores con 20 opciones
  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#8BC34A",
    "#FF9F40",
    "#9966FF",
    "#FFCD56",
    "#4BC0C0",
    "#C45850",
    "#FF6384",
    "#B39DDB",
    "#FF7043",
    "#66BB6A",
    "#29B6F6",
    "#8D6E63",
    "#FFEB3B",
    "#CDDC39",
    "#AB47BC",
    "#FF5722",
    "#00E676",
  ];

  useEffect(() => {
    searchData();
  }, []);

  // Seleccionar la clínica por defecto (clinicaId = 1) al cargar los datos
  useEffect(() => {
    if (clinicas.length > 0) {
      const defaultClinica = clinicas.find((clinica) => clinica.id === 1);
      if (defaultClinica) {
        setSelectedClinica(defaultClinica);
      }
    }
  }, [data]);

  async function searchData() {
    try {
      const url = "turnos-clinicas-por-fecha/" + today;
      setLoading(true);
      const response = await ModeloGetUrlJson("turno", null, url, null);
      setLoading(false);
      if (response.error === false) {
        setData(response.listado);
      } else {
        alert(response.errormessage);
      }
    } catch (error) {
      alert(`Error: ${error.response?.data?.mensaje || "Ocurrió un error"}`);
    } finally {
      setLoading(false);
    }
  }

  // Obtener las clínicas únicas del data
  const clinicas = [
    ...new Map(
      data.map((item) => [
        item.clinicaId,
        {
          id: item.clinicaId,
          nombre: item.clinica,
          color: item.color,
          identificador: item.identificador,
        },
      ])
    ).values(),
  ];

  // **Corregir el filtrado de especialidades por clínica seleccionada**
  const filteredEspecialidades = selectedClinica
    ? data
        .filter((item) => item.clinicaId === selectedClinica.id)
        .reduce((acc, item) => {
          const existing = acc.find(
            (especialidad) => especialidad.id === item.especialidadId
          );
          if (existing) {
            existing.turnos += item.turnos; // Sumar turnos si la especialidad ya está en la lista
          } else {
            acc.push({
              id: item.especialidadId,
              nombre: item.especialidad,
              turnos: item.turnos,
            });
          }
          return acc;
        }, [])
    : [];

  // Datos del gráfico de torta
  const pieData = {
    labels: filteredEspecialidades.map((item) => item.nombre),
    datasets: [
      {
        data: filteredEspecialidades.map((item) => item.turnos),
        backgroundColor: colors.slice(0, filteredEspecialidades.length), // Aplica colores dinámicamente
        borderColor: "#FFFFFF",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true, // Asegura que el gráfico sea responsive
    plugins: {
      title: {
        display: true,
        text: "Cantidad de Turnos por Especialidad",
        font: {
          size: 20, // Aumentar tamaño del título si es necesario
          weight: "bold",
        },
        color: "black",
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const total = context.dataset.data.reduce(
            (sum, curr) => sum + curr,
            0
          );
          const percentage = ((value / total) * 100).toFixed(1);
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}\n${percentage}%\n(${value} turnos)`;
        },
        color: "black",
        font: {
          weight: "normal",
          size: 14,
        },
        anchor: "end",
        align: "start",
        offset: 10,
      },
      legend: {
        display: false,
      },
    },
    onClick: (evt, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const selectedEspecialidadId = filteredEspecialidades[index].id;

        // Filtrar odontólogos por clínica y especialidad
        if (selectedClinica) {
          const odontologosFiltrados = data
            .filter(
              (item) =>
                item.especialidadId === selectedEspecialidadId &&
                item.clinicaId === selectedClinica.id // Filtro por clínica y especialidad
            )
            .map((item) => ({
              nombre: item.odontologo,
              turnos: item.turnos,
              percentage: (
                (item.turnos / filteredEspecialidades[index].turnos) *
                100
              ).toFixed(1),
            }));

          setSelectedEspecialidad(filteredEspecialidades[index].nombre);
          setOdontologos(odontologosFiltrados);
          setTotalTurnosEspecialidad(filteredEspecialidades[index].turnos); // Guardar total de turnos de la especialidad
          setOpen(true);
        }
      }
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement.length
        ? "pointer"
        : "default";
    },
  };

  const handleClose = () => setOpen(false);

  if (loading) {
    return (
      <div style={loadingStyle}>
        <FacebookCircularProgress />
      </div>
    );
  }

  return (
    <>
      {/* Autocompletar para seleccionar la clínica */}
      <Autocomplete
        options={clinicas}
        size="small"
        getOptionLabel={(option) => option.nombre}
        defaultValue={clinicas.find((clinica) => clinica.id === 1)} // Establecer valor predeterminado
        onChange={(event, newValue) => {
          setSelectedClinica(newValue); // Actualizar la clínica seleccionada
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: option.color,
                marginBottom: 0.5,
                marginRight: 1,
                border: 1,
                borderColor: "black",
              }}
            >
              {option.identificador}
            </Avatar>
            {option.nombre}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar Clínica"
            variant="outlined"
            fullWidth // Hacer que ocupe todo el ancho
          />
        )}
      />

      {/* Gráfico de torta filtrado por clínica */}
      {selectedClinica && (
        <div style={{ width: "500px", height: "500px" }}>
          {" "}
          {/* Aumentar tamaño */}
          <Pie data={pieData} options={options} />
        </div>
      )}

      {/* Modal con odontólogos filtrados */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Turnos por Odontólogo - {selectedEspecialidad}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Total de turnos: {totalTurnosEspecialidad}
          </Typography>{" "}
          {/* Mostrar total de turnos */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Odontólogo</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Porcentaje de Turnos</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {odontologos
                  .sort((a, b) => b.percentage - a.percentage)
                  .map((odontologo, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {odontologo.nombre}: {odontologo.turnos} turnos (
                        {odontologo.percentage}%)
                      </TableCell>
                      <TableCell>
                        <LinearProgress
                          variant="determinate"
                          value={odontologo.percentage}
                          sx={{ height: 10, borderRadius: 5 }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
};

export default PieChartTurnosPorDia;
