import React from "react";
import Box from "@mui/material/Box";
import Image from "mui-image";
import Typography from "@mui/material/Typography";

const BoxDate = () => {
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const obj = new Date();
  const dayweek = obj.getDay();
  const day = obj.getDate();
  const month = obj.getMonth();
  const year = obj.getFullYear();
  const fullText =
    days[dayweek] +
    " " +
    day.toString() +
    " de " +
    months[month] +
    " de " +
    year.toString();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "12rem",
        height: "10rem",
        color: "#64748b",
        bgcolor: "#FF9F40",
        p: 1,
        border: 1,
        borderRadius: 2,
        borderColor: "#FF9F40",
        marginTop: 0,
      }}
    >
      <Box sx={{ display: "flex", marginTop: 4, gap: 2 }}>
        <Typography
          variant="h4"
          sx={{
            color: days[dayweek].slice(0, 3) === "Dom" ? "iconred" : "avatar",
          }}
        >
          {days[dayweek].slice(0, 3)}
        </Typography>
        <Typography variant="h4" sx={{ color: "slate200" }}>
          {months[month].slice(0, 3)}
        </Typography>
      </Box>
      <Typography
        sx={{
          marginTop: -4,
          color: "white",
          fontFamily: "Roboto",
          fontSize: "7rem",
        }}
      >
        {day.toString()}
      </Typography>
    </Box>
  );
};

export default BoxDate;
