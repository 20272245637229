import React, { useRef, useState } from "react";
import {
  Box,
  Alert,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ModeloPost } from "../ModeloCrud";
import CustomModal from "./CustomModal";
import SaveCancelButtons from "../SaveCancelButtons";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import FormikCustomTextField from "../FormikCustomTextField";

const validationSchema = Yup.object({
  codigo: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(20, "Máximo 20 caracteres")
    .required("Campo obligatorio"),
  descripcion: Yup.string(),
});

const ModalCopiarPreciosLista = ({
  open,
  handleClose,
  listaId,
  ids,
  handleSuccess,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const [option, setOption] = useState("valororiginal"); // Estado para la opción seleccionada

  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };
  const handleSubmit = async (values) => {
    setShowAlert("");
    let hasError = false;
    if (ids === null || ids.length === 0) {
      setShowAlert("Seleccione al menos un codigo");
      hasError = true;
    }
    if (hasError) {
      setSubmitting(false);
      return;
    }
    const stringWithSemicolons = ids.join(";");
    console.log(stringWithSemicolons);
    try {
      const Data = {
        ids: stringWithSemicolons,
        codigo: values.codigo,
        observaciones: values.observaciones,
        valorcero: option === "valorcero" ? "valorcero" : null,
      };
      setSubmitting(true);
      const response = await ModeloPost(
        "listas-precios",
        listaId,
        "copiar-precios-lista",
        Data
      );
      console.log(response);

      if (response.error === false) {
        setSubmitting(false);
        handleClose();
        handleSuccess(response.listado.id);
      } else {
        setShowAlert(response.errormessage);
      }
    } catch (error) {
      console.error("Error actualizando precios:", error);
      alert(`Error: ${error.response?.data?.mensaje || "Ocurrió un error"}`);
    }
  };

  const inputRefs = {
    codigo: useRef(null),
    observaciones: useRef(null),
    opcion: useRef(null),
  };
  const formikRef = useRef();

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Copiar precios"
      loading={isSubmitting}
      disableActions
      maxWidth="false"
      icon={<CurrencyExchangeIcon fontSize="medium" sx={{ color: "white" }} />}
    >
      <Box>
        <Formik
          initialValues={{ codigo: null, observaciones: null }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box
                sx={{ display: "flex", flexDirection: "column", mt: 2, mb: 2 }}
                gap={2}
              >
                <FormControl sx={{ mb: 2 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Precio
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={option}
                    onChange={handleOptionChange}
                    inputRef={inputRefs.opcion}
                  >
                    <FormControlLabel
                      value="valororiginal"
                      control={<Radio />}
                      label="Valor original"
                    />
                    <FormControlLabel
                      value="valorcero"
                      control={<Radio />}
                      label="Valor 0 (cero)"
                    />
                  </RadioGroup>
                </FormControl>

                <FormikCustomTextField
                  name="codigo"
                  label="Nombre"
                  autoFocus
                  inputRef={inputRefs.codigo} // Referencia al campo actual
                  nextInputRef={inputRefs.observaciones} // Referencia al siguiente campo
                />
                <FormikCustomTextField
                  name="observaciones"
                  label="Observaciones"
                  multiline
                  rows={10}
                  inputRef={inputRefs.observaciones} // Referencia al campo actual
                  nextInputRef={null} // Referencia al siguiente campo
                />
              </Box>
              {showAlert?.length > 0 && (
                <Alert variant="filled" severity="error">
                  {showAlert}
                </Alert>
              )}
              <Divider />
              <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
                <SaveCancelButtons
                  handleCancel={handleClose}
                  loading={isSubmitting}
                  isModal={true}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomModal>
  );
};

export default ModalCopiarPreciosLista;
