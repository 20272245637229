import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Collapse,
  Checkbox,
  TextField,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DateToArgString,
  CapSentence,
  formatNumberES,
  hasPermission,
} from "./utils/Utils";
import PaidIcon from "@mui/icons-material/Paid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import crypto from "./Crypto";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const useStyles = makeStyles({
  smallRow: {
    height: 20, // Adjust this value as needed for a smaller row height
  },
  headerCell: {
    backgroundColor: "#475569",
    color: "white",
  },
  iconButton: {
    padding: "5px", // Reduce padding for a smaller button
  },
});

const RenderCustomTable = ({
  data,
  columns,
  iconColumns,
  showHeader,
  alternateRowColor,
  maxWidth,
  paddingLeft,
  paddingRight,
  actions,
  dataChild,
  columnsChild,
  parentIdColumn,
  childTitle,
  selectable,
  onSelectedRowsChange,
  showSearch,
  handleNew,
}) => {
  const classes = useStyles();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const formatCellValue = (column, row) => {
    if (column.isDate) return DateToArgString(row[column.field]);
    if (column.capitalize) return capitalize(row[column.field]);
    if (column.lowercase)
      return row[column.field] !== null ? row[column.field].toLowerCase() : "";
    if (column.isClinic) return clinica(row);
    if (column.isCodigoHc) return codigoHc(row);
    if (column.isCodigoPrec) return codigoPrec(row);
    if (column.isPrecio) return precio(row);
    if (column.formatNumber) return formatNumberES(row[column.field], 2);
    return row[column.field];
  };

  const [expandedRows, setExpandedRows] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(Array.isArray(data) ? data : []);
  }, [data]);

  const handleExpandClick = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const removeFilter = () => {
    setFilteredData(data);
    setSearchTerm("");
  };

  const handleSelectRow = (rowId) => {
    const isSelected = selectedRows.includes(rowId);
    const newSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(newSelectedRows);
    if (onSelectedRowsChange) {
      onSelectedRowsChange(newSelectedRows, rowId, isSelected);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allRowIds = filteredData.map((row) => row[parentIdColumn]);
      setSelectedRows(allRowIds);
      if (onSelectedRowsChange) {
        onSelectedRowsChange(allRowIds);
      }
    } else {
      setSelectedRows([]);
      if (onSelectedRowsChange) {
        onSelectedRowsChange([]);
      }
    }
  };

  const isSelected = (rowId) => selectedRows.includes(rowId);

  const getCellStyle = (column, value) => {
    // Conditional backgroundColorgetCellStyle
    const baseStyle = {};
    if (value === "valores aca") {
      baseStyle.backgroundColor = "#e0e7ff";
    } else if (value === "low") {
      baseStyle.backgroundColor = "green[100]";
    } else {
      baseStyle.backgroundColor = "red[100]";
    }

    return { ...baseStyle, ...column.style };
  };

  const capitalize = (str) => {
    return str !== null && typeof str !== "undefined" ? CapSentence(str) : "";
  };
  const clinica = (row) => {
    return (
      <Avatar
        sx={{
          width: 24,
          height: 24,
          bgcolor: row.color,
          marginBottom: 0.5,
          border: 1,
          borderColor: "black",
        }}
      >
        {row.identificador}
      </Avatar>
    );
  };
  const codigoHc = (row) => {
    if (
      row.presente === "SI" &&
      row.codigo_nomenclador !== null &&
      row.codigo_nomenclador.length > 0
    ) {
      return (
        <Typography
          sx={{
            border: "1px solid ",
            borderRadius: 1,
            color: "black",
            bgcolor: "lightgreen",
            fontSize: 12,
            width: 100,
            height: 26,
            paddingLeft: 2,
            paddingTop: 0.5,
            paddingBottom: 0,
            fontWeight: "medium",
          }}
        >
          {row.codigo_nomenclador.toString().replace(/:/g, ".")}
        </Typography>
      );
    } else if (
      row.presente === "SI" &&
      (row.codigo_nomenclador === null || row.codigo_nomenclador.length === 0)
    ) {
      return (
        <Typography
          variant="body2"
          sx={{
            border: "1px solid ",
            borderRadius: 1,
            color: "black",
            bgcolor: "lightyellow",
            fontSize: 12,
            width: 100,
            height: 30,
            paddingLeft: 2,
            paddingTop: 0.5,
            fontWeight: "medium",
          }}
        >
          Sin Cargar
        </Typography>
      );
    } else if (row.presente !== "SI") {
      return (
        <Typography
          variant="body2"
          sx={{
            border: "1px solid ",
            borderRadius: 1,
            color: "black",
            bgcolor: "lightred",
            fontSize: 12,
            width: 100,
            height: 30,
            paddingLeft: 2,
            paddingTop: 0.5,
            fontWeight: "medium",
          }}
        >
          Ausente
        </Typography>
      );
    }
  };
  const codigoPrec = (row) => {
    return (
      <Typography
        variant="body2"
        sx={{
          border: "1px solid ",
          borderRadius: 1,
          color: "black",
          bgcolor: "#eff6ff",
          fontSize: 12,
          width: 100,
          height: 30,
          paddingLeft: 2,
          paddingTop: 0.5,
          fontWeight: "medium",
        }}
      >
        {row.codigo_nomenclador.toString().replace(":", ".")}
      </Typography>
    );
  };
  const precio = (row) => {
    return row.precio > 0 ? (
      <Avatar
        variant="rounded"
        sx={{
          border: "1px solid ",
          color: "black",
          bgcolor: "#eff6ff",
          fontSize: 12,
          width: 135,
          height: 30,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <PaidIcon
            sx={{
              alignContent: "start",
              color: "primary.hover",
              fontSize: 20,
              mr: { sm: 2 },
              ml: { xs: -1, sm: 0 },
            }}
          />
          <Typography
            sx={{
              fontSize: 12,
              width: 80,
              textAlign: "right",
              fontWeight: "medium",
              marginTop: 0.25,
            }}
          >
            {formatNumberES(row.precio, 2)}
          </Typography>
        </Box>
      </Avatar>
    ) : (
      ""
    );
  };

  const getChildRows = (parentId) => {
    // Filtrar los registros hijos basados en el ID del padre
    return dataChild
      ? dataChild.filter((childRow) => childRow[parentIdColumn] === parentId)
      : [];
  };

  const handleSearch = (event) => {
    const value = event?.target?.value || ""; // Ensure event and target exist
    setSearchTerm(value);

    if (value) {
      const filtered = data.filter((row) =>
        columns.some((column) =>
          row[column.field]
            ?.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  return (
    <Container
      style={{
        maxWidth: maxWidth,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
      }}
    >
      {showSearch && data?.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {handleNew && (
            <IconButton aria-label="new" onClick={handleNew}>
              <AddRoundedIcon
                sx={{
                  fontSize: 48,
                  color: "icongreen",
                }}
              />
            </IconButton>
          )}
          <TextField
            label="Buscar"
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon
                    onClick={removeFilter}
                    edge="end"
                    fontSize="medium"
                    cursor="pointer"
                  />
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: 0 }}
          />
        </Box>
      )}
      <TableContainer component={Paper}>
        {data?.length > 0 && (
          <Table size="small" sx={{ fontSize: "12px" }}>
            {showHeader && data.length > 0 && (
              <TableHead>
                <TableRow className={classes.smallRow}>
                  {selectable && (
                    <TableCell
                      padding="checkbox"
                      className={classes.headerCell}
                    >
                      <Checkbox
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < filteredData.length
                        }
                        checked={
                          filteredData.length > 0 &&
                          selectedRows.length === filteredData.length
                        }
                        onChange={handleSelectAllClick}
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                          },
                          "&.MuiCheckbox-indeterminate": {
                            color: "white",
                          },
                        }}
                      />
                    </TableCell>
                  )}
                  {dataChild && (
                    <TableCell
                      key="expand"
                      align="center"
                      className={classes.headerCell}
                    >
                      Items
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell
                      key={column.field}
                      align={column.rigthAign === true ? "right" : "left"}
                      className={classes.headerCell}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                  {actions && (
                    <TableCell
                      align="center"
                      className={classes.headerCell}
                    ></TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {filteredData.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={index}
                    className={classes.smallRow}
                    style={{
                      backgroundColor:
                        alternateRowColor && index % 2 !== 0
                          ? "white"
                          : "#f5f5f5",
                    }}
                  >
                    {selectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.includes(row[parentIdColumn])}
                          onChange={() => handleSelectRow(row[parentIdColumn])}
                        />
                      </TableCell>
                    )}
                    {dataChild && (
                      <TableCell>
                        <IconButton
                          style={{ padding: "0px 6px" }}
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleExpandClick(row[parentIdColumn])}
                        >
                          {expandedRows[row[parentIdColumn]] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align={column.rigthAign === true ? "right" : "left"}
                        style={getCellStyle(column, row[column.field])}
                        sx={{ fontSize: 13 }}
                      >
                        {iconColumns && iconColumns[column.field]
                          ? iconColumns[column.field]({
                              value: row[column.field],
                            })
                          : formatCellValue(column, row)}
                      </TableCell>
                    ))}
                    {actions && (
                      <TableCell>
                        <Box sx={{ display: "flex" }}>
                          {actions.map((action, actionIndex) =>
                            (!action.hidden || !action.hidden(row)) &&
                            hasPermission(
                              userRoles,
                              action.requiredPermission
                            ) ? (
                              <Tooltip
                                key={actionIndex}
                                title={action.tooltip || ""}
                                placement="top"
                              >
                                {action.render ? (
                                  <span key={actionIndex}>
                                    {action.render(row)}
                                  </span>
                                ) : (
                                  <IconButton
                                    key={actionIndex}
                                    style={{ padding: "0px 6px" }}
                                    onClick={() => action.handle(row)}
                                    sx={action.sx}
                                  >
                                    {React.createElement(action.icon, {
                                      sx: { fontSize: "20px" },
                                    })}
                                  </IconButton>
                                )}
                              </Tooltip>
                            ) : null
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                  {dataChild && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={columns.length + 2}
                      >
                        <Collapse
                          in={expandedRows[row[parentIdColumn]]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            {childTitle && (
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                              >
                                {childTitle}
                              </Typography>
                            )}
                            <Table size="small">
                              <TableHead>
                                <TableRow
                                  style={{
                                    backgroundColor: "#eff6ff",
                                  }}
                                >
                                  {columnsChild.map((column) => (
                                    <TableCell key={column.field}>
                                      {column.headerName}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {getChildRows(row[parentIdColumn]).map(
                                  (childRow, childIndex) => (
                                    <TableRow key={childIndex}>
                                      {columnsChild.map((column) => (
                                        <TableCell key={column.field}>
                                          {formatCellValue(column, childRow)}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Container>
  );
};

RenderCustomTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnNames: PropTypes.objectOf(PropTypes.string).isRequired,
  showHeader: PropTypes.bool,
  alternateRowColor: PropTypes.bool,
  maxWidth: PropTypes.string,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  iconColumns: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.string),
  dataChild: PropTypes.arrayOf(PropTypes.string),
  columnsChild: PropTypes.arrayOf(PropTypes.string),
  parentIdColumn: PropTypes.string,
  childTitle: PropTypes.string,
  selectable: PropTypes.bool, // New prop to enable selectable rows
  onSelectedRowsChange: PropTypes.func, // Callback function for when selected rows change
  showSearch: PropTypes.bool,
};

RenderCustomTable.defaultProps = {
  showHeader: true,
  alternateRowColor: true,
  maxWidth: "100%",
  paddingLeft: 24,
  paddingRight: 24,
  iconColumns: null,
  actions: null,
  dataChild: null,
  columnsChild: null,
  parentIdColumn: null,
  childTitle: null,
  selectable: false, // Default to not selectable
  onSelectedRowsChange: null, // No-op if not provided
  showSearch: false,
};

export default RenderCustomTable;
