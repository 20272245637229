/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import ApiService from "../apis/ApiService";
import loadingGif from "../assetss/img/loading_64.gif";
import AvatarAzul from "../assetss/img/AvatarAzul96.png";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import bg1 from "../assetss/img/bgop1.png";
import bg2 from "../assetss/img/bgop2.png";
import bg3 from "../assetss/img/bgop3.png";
import bg4 from "../assetss/img/bgop4.jpg";
import bg5 from "../assetss/img/bgop5.jpg";
import bg6 from "../assetss/img/bgop6.jpg";
import bg7 from "../assetss/img/bgop7.jpg";
import bg8 from "../assetss/img/bgop8.jpg";
import bg9 from "../assetss/img/bgop9.jpg";
import bg10 from "../assetss/img/bgop10.jpg";
import bg11 from "../assetss/img/bgop11.jpg";
import bg12 from "../assetss/img/bgop12.jpg";
import bg13 from "../assetss/img/bgop13.jpg";
import bg14 from "../assetss/img/bgop14.jpg";
import bg15 from "../assetss/img/bgop15.jpg";
import bg16 from "../assetss/img/bgop16.jpg";
import bg17 from "../assetss/img/bgop17.jpg";
import bg18 from "../assetss/img/bgop18.jpg";
import bg19 from "../assetss/img/bgop19.jpg";
import bg20 from "../assetss/img/bgop20.jpg";
import bg21 from "../assetss/img/bgop21.jpg";
import bg22 from "../assetss/img/bgop22.jpg";
import bg24 from "../assetss/img/bgop24.jpg";
import bg25 from "../assetss/img/bgop25.jpg";
import bg26 from "../assetss/img/bgop26.jpg";
import bg27 from "../assetss/img/bgop27.jpg";
import crypto from "./Crypto";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoggedUser, ModeloGetUrlJson } from "./ModeloCrud";
import InputAdornment from "@mui/material/InputAdornment";
import { CapSentenceArray, getRandomNumber } from "./utils/Utils";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.consulmed.com.ar/">
        www.consulmed.com.ar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const validationSchema = yup.object({
  email: yup
    .string("Ingrese su email")
    .email("Ingrese un email válido")
    .required("Ingrese su email"),
  password: yup.string("Ingrese su clave").required("Ingrese su clave"),
});
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Login = (props) => {
  const formik = useFormik({
    initialValues: {
      email:
        crypto.decryptLocalStorage("user_email") !== null
          ? crypto.decryptLocalStorage("user_email")
          : "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const bgimages = [
    bg1,
    bg2,
    bg3,
    bg4,
    bg5,
    bg6,
    bg7,
    bg8,
    bg9,
    bg10,
    bg11,
    bg12,
    bg13,
    bg14,
    bg15,
    bg16,
    bg17,
    bg18,
    bg19,
    bg20,
    bg21,
    bg22,
    bg24,
    bg25,
    bg26,
    bg27,
  ];
  const $ = require("jquery");
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [lastLoggedEmail, setLastLoggedEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showform, setShowForm] = useState(true);
  let [selectedImage, setSelectedImage] = useState(
    getRandomNumber(0, bgimages.length - 1)
  );

  //  alert(window.location.hostname);
  //
  let REACT_APP_WEBAPI_URL = "";
  //

  const REACT_APP_URLAPI = "webapi";
  const ENV_URL = process.env.REACT_APP_WEBAPI_URL;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  const hideWarningModal = () => {
    setModalMessage(null);
    setModalWarningOpen(false);
  };

  const validate = yup.object().shape({
    username: yup
      .string()
      .required("Campo Obligatorio.")
      .email("Ingrese un Email válido."),
    password: yup.string().required("Campo Obligatorio."),
  });

  useEffect(() => {
    setSelectedImage = getRandomNumber(0, 2);
    // let storedjwttoken = crypto.decryptLocalStorage("jwtcr");
    let storedjwttoken = null;
    //let lastLogged = crypto.decryptLocalStorage("user_email");
    let lastLogged = null;
    if (
      typeof lastLogged !== "undefined" &&
      lastLogged !== null &&
      lastLogged.length > 0
    ) {
      setLastLoggedEmail(lastLogged);
    }
    if (
      typeof storedjwttoken !== "undefined" &&
      storedjwttoken !== null &&
      storedjwttoken.length > 0
    ) {
      searchLoggedUser(storedjwttoken);
    } else {
      let lastLogged = crypto.decryptLocalStorage("user_email");
      sessionStorage.clear();
      localStorage.clear();
      if (
        typeof lastLogged !== "undefined" &&
        lastLogged !== null &&
        lastLogged.length > 0
      ) {
        crypto.encryptLocalStorage("user_email", lastLogged);
      }
      setShowForm(true);
    }
  }, []);

  $(document).ready(function () {
    var allInputs = $(":text:visible"); //(1)collection of all the inputs I want (not all the inputs on my form)
    $(":text").on("keydown", function (e) {
      //(2)When an input field detects a keydown event
      if (e.keyCode === 13) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) + 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
      if (e.keyCode === 27) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) - 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
    });
    /*    if ($('#username').length) {
               $('#username').focus()
           } */
  });

  const [errorlog, SetErrorLog] = useState(null);

  const submitForm = (data) => {
    let url = "user/login";
    SetErrorLog(null);
    setLoading(true);
    ApiService.login(url, data)
      .then((response) => {
        if (response.data.error === true) {
          setLoading(false);

          formik.setErrors({
            email: "Email o Clave Inválidos.",
            password: "Email o Clave Inválidos.",
          });
        } else {
          if (REACT_APP_URLAPI !== "webapi") {
            crypto.encryptLocalStorage("user_id", response.data.listado.id);
            crypto.encryptLocalStorage(
              "user_name",
              response.data.listado.nombre
            );
            crypto.encryptLocalStorage(
              "session_key",
              response.data.listado.session_key
            );
            GetInitialData();
            props.history.push("navbar");
            props.history.push("home");
            window.location.reload();
          } else {
            enterhome(response.data, "form");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setModalMessage(error);
        setModalErrorOpen(true);
      });
  };

  async function searchLoggedUser(jwtoken) {
    let result = await LoggedUser(jwtoken);
    if (
      typeof result.user !== "undefined" &&
      typeof result.error === "undefined"
    ) {
      enterhome(result.user, "storage");
    } else {
      let lastLogged = crypto.decryptLocalStorage("user_email");
      sessionStorage.clear();
      localStorage.clear();
      if (
        typeof lastLogged !== "undefined" &&
        lastLogged !== null &&
        lastLogged.length > 0
      ) {
        crypto.encryptLocalStorage("user_email", lastLogged);
      }
      setShowForm(true);
    }
    return;
  }

  function enterhome(user, loginfrom) {
    const historiapac = crypto.decryptLocalStorage("historialpacientes");
    sessionStorage.clear();
    localStorage.clear();
    console.log(user);
    if (
      typeof historiapac !== "undefined" &&
      historiapac !== null &&
      historiapac.length > 0
    ) {
      crypto.encryptLocalStorage("historialpacientes", historiapac);
    }
    try {
      if (loginfrom === "form") {
        crypto.encryptLocalStorage("jwtcr", user.token.original.access_token);
        crypto.encryptLocalStorage(
          "user_email",
          user.token.original.user.email
        );
        crypto.encryptLocalStorage(
          "user_id",
          user.token.original.user.userid_app
        );
        crypto.encryptLocalStorage("user_api_id", user.token.original.user.id);
        crypto.encryptLocalStorage("user_name", user.token.original.user.name);
        crypto.encryptLocalStorage("caja_id", user.token.original.user.caja_id);
      } else {
        crypto.encryptLocalStorage("jwtcr", user.token);
        crypto.encryptLocalStorage("user_email", user.email);
        crypto.encryptLocalStorage("user_id", user.userid_app);
        crypto.encryptLocalStorage("user_api_id", user.id);
        crypto.encryptLocalStorage("user_name", user.name);
        crypto.encryptLocalStorage("caja_id", user.caja_id);
      }
      GetInitialData();
    } catch (e) {
      alert(e.message);
    }
  }

  async function GetInitialData() {
    try {
      crypto.encryptLocalStorage("primarymain", "#1976d2");
      let resultBeta = await ApiService.isBeta();
      let isBeta = resultBeta.apiurl.includes("test") ? true : false;
      crypto.encryptLocalStorage("is-beta", isBeta);
      let result = await ModeloGetUrlJson(
        "inicio",
        null,
        "iniciar-web-app",
        ""
      );
      if (result.error == "true") {
        setLoading(false);
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      } else {
        crypto.encryptLocalStorage("user_roles", result.permisos);
        var derivadores = [];
        result.coes.reduce(function (res, value) {
          if (!res[value.odontologo]) {
            res[value.odontologo] = { odontologo: value.odontologo };
            derivadores.push(res[value.odontologo]);
          }
          return res;
        }, {});
        const tabla = result.derivadores;
        for (let i = 0; i < tabla.length; i++) {
          const row = {};
          row["odontologo"] = tabla[i].valor;
          derivadores.push(row);
        }
        const derivador = CapSentenceArray(derivadores, ["odontologo"]);
        crypto.encryptDataStorage("motivos_especialidades", result.motivos);
        crypto.encryptDataStorage("piezas_dentales", result.piezas);
        crypto.encryptDataStorage("grupos_dentales", result.piezasgrupos);
        crypto.encryptDataStorage("derivadores", derivador);
        crypto.encryptDataStorage("allcoes", result.coes);
        crypto.encryptDataStorage("nomencladores", result.nomencladores);
        crypto.encryptDataStorage("centros", result.centros);
        crypto.encryptDataStorage("rfichado", result.rfichado);
        crypto.encryptDataStorage("cajas", result.cajas);
        crypto.encryptDataStorage("quejas", result.quejas);
        crypto.encryptDataStorage("tablafull", result.tablafull);
        crypto.encryptDataStorage("feriados", result.feriados);
        crypto.encryptDataStorage("consultorios", result.consultorios);
        crypto.encryptDataStorage("laboratorios", result.laboratorios);
        crypto.encryptDataStorage("useravatar", result.avatar);
        setLoading(false);
        props.history.push("navbar");
        props.history.push("home");
        window.location.reload();
      }
    } catch (error) {
      var mainurl = window.location.origin;
      window.location = mainurl;
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }

    return;
  }

  return (
    <>
      {showform && (
        <Container sx={{}} maxWidth={false}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <Grid
              container
              component="main"
              sx={{
                boxShadow: {
                  xs: 0,
                  md: 3,
                },
              }}
              maxWidth={"xl"}
            >
              <Grid
                item
                sm={false}
                lg={8}
                sx={{
                  //  backgroundImage:
                  //    "url(https://source.unsplash.com/random?wallpapers)",

                  backgroundImage: `url(${bgimages[selectedImage]})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  boxShadow: {
                    xs: 0,
                    md: 3,
                  },
                }}
              />
              <Grid
                item
                sm={12}
                lg={4}
                sx={{
                  backgroundColor: "#fff",
                  boxShadow: {
                    xs: 0,
                    md: 3,
                  },
                }}
              >
                <Box
                  sx={{
                    my: 8,
                    mx: { xs: 0, md: 4 },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt="Logo"
                    src={AvatarAzul}
                    sx={{
                      //        display: { xs: "flex", lg: "none" },
                      width: 96,
                      height: 96,
                      justifyContent: "center",
                      mb: 4,
                    }}
                  />
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                      mb: 4,
                    }}
                  >
                    Iniciar sesión
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      id="email"
                      label="Email"
                      size="medium"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.errors.email)}
                      helperText={
                        formik.errors.email ? formik.errors.email : " "
                      }
                      FormHelperTextProps={{
                        sx: { minHeight: "1.5em" }, // Reservar espacio para el helper text
                      }}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        style: {},
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        mb: 4,
                      }}
                    />
                    <TextField
                      id="password"
                      label="Clave"
                      size="medium"
                      type={showPassword ? "text" : "password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.errors.password)}
                      helperText={
                        formik.errors.password ? formik.errors.password : " "
                      }
                      FormHelperTextProps={{
                        sx: { minHeight: "1.5em" }, // Reservar espacio para el helper text
                      }}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        style: {},
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />{" "}
                    <Box
                      sx={{
                        mt: 5,
                      }}
                    >
                      <LoadingButton
                        size="medium"
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<LoginIcon />}
                        sx={{
                          backgroundColor: "primary.main",
                          ":hover": {
                            bgcolor: "primary.hover", // theme.palette.primary.main
                            color: "white",
                          },
                          textTransform: "capitalize",
                          height: 48,
                        }}
                      >
                        <span>Ingresar</span>
                      </LoadingButton>
                    </Box>
                  </form>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      {!showform && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <img alt="Loading64" src={loadingGif} />
        </div>
      )}
    </>
  );
};

export default Login;
