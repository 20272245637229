import React, { useRef, useState } from "react";
import { Box, Alert, Divider } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ModeloPost } from "../ModeloCrud";
import CustomModal from "./CustomModal";
import SaveCancelButtons from "../SaveCancelButtons";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import FormikCustomTextField from "../FormikCustomTextField";

const validationSchema = Yup.object({
  precioActual: Yup.number()
    .min(0, "El valor debe ser mayor o igual a 0")
    .required("Requerido"),
  precioNuevo: Yup.number()
    .min(0, "El valor debe ser mayor o igual a 0")
    .notOneOf(
      [Yup.ref("precioActual"), null],
      "Los valores no deben ser iguales"
    )
    .required("Requerido"),
});

const ModalReemplazarPreciosLista = ({
  open,
  handleClose,
  listaId,
  handleSuccess,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const handleSubmit = async (values) => {
    setShowAlert("");
    console.log(values);
    try {
      const Data = {
        precioactual: values.precioActual,
        precioreemplazo: values.precioNuevo,
      };
      setSubmitting(true);
      const response = await ModeloPost(
        "listas-precios",
        listaId,
        "reemplazar-precios-lista",
        Data
      );
      console.log(response);

      if (response.error === false) {
        setSubmitting(false);
        handleClose();
        handleSuccess();
      } else {
        setShowAlert(response.errormessage);
      }
    } catch (error) {
      console.error("Error actualizando precios:", error);
      alert(`Error: ${error.response?.data?.mensaje || "Ocurrió un error"}`);
    }
  };

  const inputRefs = {
    precioActual: useRef(null),
    precioNuevo: useRef(null),
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Reemplazar precios"
      loading={isSubmitting}
      disableActions
      maxWidth="false"
      icon={<CurrencyExchangeIcon fontSize="medium" sx={{ color: "white" }} />}
    >
      <Box>
        <Formik
          initialValues={{ precioActual: null, precioNuevo: null }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box
                sx={{ display: "flex", direction: "row", mt: 4, mb: 4 }}
                gap={4}
              >
                <FormikCustomTextField
                  type="number"
                  name="precioActual"
                  label="Precio Actual"
                  autoFocus
                  inputRef={inputRefs.precioActual} // Referencia al campo actual
                  nextInputRef={inputRefs.precioNuevo} // Referencia al siguiente campo
                />
                <FormikCustomTextField
                  type="number"
                  name="precioNuevo"
                  label="Precio Nuevo"
                  inputRef={inputRefs.precioNuevo} // Referencia al campo actual
                  nextInputRef={null} // Referencia al siguiente campo
                />
              </Box>
              {showAlert?.length > 0 && (
                <Alert variant="filled" severity="error">
                  {showAlert}
                </Alert>
              )}
              <Divider />
              <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
                <SaveCancelButtons
                  handleCancel={handleClose}
                  loading={isSubmitting}
                  isModal={true}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomModal>
  );
};

export default ModalReemplazarPreciosLista;
