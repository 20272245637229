import React, { useRef, useEffect, useState } from "react";
import {
  Grid,
  Switch,
  FormControlLabel,
  Paper,
  Container,
  Box,
  Alert,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import CustomTextField from "../CustomTextField";
import CustomAutoComplete from "../CustomAutoComplete";
import SaveCancelButtons from "../SaveCancelButtons";
import BackPage from "../BackPage";
import ProtectedRoute from "../ProtectedRoute";
import crypto from "../Crypto";
import FacebookCircularProgress from "../FacebookCircularProgress";
import { ModeloGetUrlJson, ModeloPost, ModeloUpdate } from "../ModeloCrud";
import { CapSentence } from "../utils/Utils";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

const InsertUpdateCliente = () => {
  const [grupos, setGrupos] = useState([]);
  const [loading, setLoading] = useState(true); // Control loading state
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState(null); // Control error state
  const [saveError, setSaveError] = useState(null);
  const [row, setRow] = useState(null);
  const location = useLocation();
  // Create refs for each field
  const inputRefs = {
    codigo: useRef(null),
    razon_social: useRef(null),
    denominacion_comercial: useRef(null),
    cuit: useRef(null),
    fecha_alta: useRef(null),
    fecha_baja: useRef(null),
    tipo: useRef(null),
    grupo_prepaga: useRef(null),
  };
  const tablafull = crypto.decryptDataStorage("tablafull");
  const tipos = tablafull
    .filter((obj) => obj.codigo_tabla === "TIPO_CLIENTE")
    .map((obj) => ({ codigo: obj.valor, descripcion: obj.descripcion }));

  const iconStyles = {
    color: "avatar",
    fontSize: 32,
    marginLeft: 1,
    marginRight: 1,
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await ModeloGetUrlJson(
          "grupos-prepagas",
          null,
          null,
          null
        );
        if (!response.error) {
          setGrupos(response.listado);
          if (typeof location.currentCliente !== "undefined") {
            const myRow = location.currentCliente;
            if (myRow.grupos_prepaga_id) {
              const selectedGrupo = response.listado.find(
                (grupo) => grupo.id === myRow.grupos_prepaga_id
              );
              if (selectedGrupo) {
                formik.setFieldValue("grupos_prepaga_id", selectedGrupo);
              }
            }
            if (myRow.tipo) {
              const selectedTipo = tipos.find(
                (tipo) => tipo.codigo === myRow.tipo
              );
              if (selectedTipo) {
                formik.setFieldValue("tipo", selectedTipo);
              }
            }
          }
          setError(false);
        } else {
          setError(true);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    console.log(location.currentCliente);
    if (typeof location.currentCliente !== "undefined") {
      const myRow = location.currentCliente;
      setRow(myRow);
      formik.setFieldValue("codigo", myRow.codigo);
      formik.setFieldValue("razon_social", myRow.razon_social);
      formik.setFieldValue(
        "denominacion_comercial",
        myRow.denominacion_comercial
      );
      formik.setFieldValue("cuit", myRow.cuit);
      if (myRow.fecha_alta !== null) {
        formik.setFieldValue("fecha_alta", dayjs(myRow.fecha_alta));
      }
      if (myRow.fecha_baja !== null) {
        formik.setFieldValue("fecha_baja", dayjs(myRow.fecha_baja));
      }
      if (myRow.credencial_propia === "S") {
        formik.setFieldValue("credencial_propia", true);
      }
      if (myRow.presenta_padron === "S") {
        formik.setFieldValue("presenta_padron", true);
      }
      if (myRow.turnos_habilitados === "S") {
        formik.setFieldValue("turnos_habilitados", true);
      }
      if (myRow.precios_bonificados === "S") {
        formik.setFieldValue("precios_bonificados", true);
      }
    }
    fetchGroups();
  }, []);

  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];
  const history = useHistory();

  function handleClickVolver() {
    history.push("/clientes");
  }

  const formik = useFormik({
    initialValues: {
      codigo: row?.codigo || "",
      razon_social: row?.razon_social || "",
      denominacion_comercial: row?.denominacion_comercial || "",
      cuit: row?.cuit || "",
      fecha_alta: null,
      fecha_baja: null,
      credencial_propia: row?.credencial_propia === "S",
      presenta_padron: row?.presenta_padron === "S",
      turnos_habilitados: row?.turnos_habilitados === "S",
      precios_bonificados: row?.precios_bonificados === "S",
      tipo: null,
      grupos_prepaga_id: null,
    },
    validationSchema: Yup.object({
      codigo: Yup.string()
        .max(5, "Máximo 5 caracteres")
        .required("Campo obligatorio"),
      razon_social: Yup.string()
        .max(200, "Máximo 200 caracteres")
        .required("Campo obligatorio"),
      denominacion_comercial: Yup.string()
        .max(200, "Máximo 200 caracteres")
        .required("Campo obligatorio"),
      cuit: Yup.string()
        .matches(/^[0-9]{11}$/, "Debe tener 11 dígitos")
        .required("Campo obligatorio"),
      fecha_alta: Yup.date().required("Campo obligatorio"),
      fecha_baja: Yup.date().nullable(),
    }),
    onSubmit: (values) => {
      grabarCliente(values);
    },
  });

  async function grabarCliente(values) {
    setSaveLoading(true);
    setSaveError(null);
    const Data = {
      codigo: values.codigo,
      razon_social: values.razon_social,
      denominacion_comercial: values.denominacion_comercial,
      cuit: values.cuit,
      fecha_alta:
        values.fecha_alta !== null
          ? values.fecha_alta.toISOString().slice(0, 10)
          : null,
      fecha_baja:
        values.fecha_baja !== null
          ? values.fecha_baja.toISOString().slice(0, 10)
          : null,
      credencial_propia: values.credencial_propia ? "S" : "N",
      presenta_padron: values.presenta_padron ? "S" : "N",
      turnos_habilitados: values.turnos_habilitados ? "S" : "N",
      precios_bonificados: values.precios_bonificados ? "S" : "N",
      tipo: values.tipo !== null ? values.tipo.codigo : null,
      grupos_prepaga_id:
        values.grupos_prepaga_id !== null ? values.grupos_prepaga_id.id : null,
    };
    let result = null;
    if (!row) {
      result = await ModeloPost("prepaga", null, null, Data);
    } else {
      result = await ModeloUpdate("prepaga", row.id, Data);
    }
    setSaveLoading(false);

    if (result.error === false) {
      crypto.encryptDataStorage("currentcliente", result.listado[0]);
      handleClickVolver();
    } else {
      setSaveError(result.errormessage);
    }
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={8}>
        <FacebookCircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={8}>
        <Alert variant="filled" severity="error">
          Error al buscar los grupos de clientes.
        </Alert>
      </Box>
    );
  }

  return (
    <ProtectedRoute userRoles={userRoles} requiredPermission={"ALTA CPREPAGAS"}>
      <BackPage text="Volver a clientes" handleClick={handleClickVolver} />
      <form onSubmit={formik.handleSubmit}>
        <Container
          maxWidth="xl"
          sx={{ marginTop: 2, borderBottom: 1, borderColor: "grey.400" }}
        >
          <Grid xs={12} sm={6}>
            <Paper
              elevation={2}
              sx={{ width: "50%", height: "55px", marginBottom: 4 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  height: "100%", // Ensure the Box uses the full height
                }}
              >
                {row ? (
                  <EditIcon sx={iconStyles} />
                ) : (
                  <AddIcon sx={iconStyles} />
                )}

                <Typography variant="h6">
                  {row ? "Editar cliente" : "Nuevo cliente"}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: 4 }}>
            <Grid item xs={12} sm={6} md={2}>
              <CustomTextField
                id="codigo"
                label="Código"
                value={formik.values.codigo}
                onChange={formik.handleChange}
                inputRef={inputRefs.codigo}
                nextInputRef={inputRefs.razon_social}
                formik={formik}
                autoFocus={true}
                width={"200px"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <CustomTextField
                id="razon_social"
                label="Razón Social"
                value={formik.values.razon_social}
                onChange={formik.handleChange}
                inputRef={inputRefs.razon_social}
                nextInputRef={inputRefs.denominacion_comercial}
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <CustomTextField
                id="denominacion_comercial"
                label="Denominación Comercial"
                value={formik.values.denominacion_comercial}
                onChange={formik.handleChange}
                inputRef={inputRefs.denominacion_comercial}
                nextInputRef={inputRefs.cuit}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} />
            <Grid item xs={12} sm={6} md={2}>
              <CustomTextField
                id="cuit"
                label="CUIT"
                value={formik.values.cuit}
                onChange={formik.handleChange}
                inputRef={inputRefs.cuit}
                nextInputRef={inputRefs.fecha_alta}
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <CustomTextField
                id="fecha_alta"
                label="Fecha Alta"
                value={formik.values.fecha_alta}
                onChange={formik.handleChange}
                inputRef={inputRefs.fecha_alta}
                nextInputRef={inputRefs.fecha_baja}
                formik={formik}
                fieldType="date"
                width="200px"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <CustomTextField
                id="fecha_baja"
                label="Fecha Baja"
                value={formik.values.fecha_baja}
                onChange={formik.handleChange}
                inputRef={inputRefs.fecha_baja}
                nextInputRef={inputRefs.tipo}
                formik={formik}
                fieldType="date"
                width="200px"
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6} md={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="credencial_propia"
                    checked={formik.values.credencial_propia}
                    onChange={formik.handleChange}
                  />
                }
                label="Credencial Propia"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="presenta_padron"
                    checked={formik.values.presenta_padron}
                    onChange={formik.handleChange}
                  />
                }
                label="Presenta Padrón"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="turnos_habilitados"
                    checked={formik.values.turnos_habilitados}
                    onChange={formik.handleChange}
                  />
                }
                label="Turnos Habilitados"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="precios_bonificados"
                    checked={formik.values.precios_bonificados}
                    onChange={formik.handleChange}
                  />
                }
                label="Precios Bonificados"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} />
            <Grid item xs={12} sm={6} md={4}>
              <CustomAutoComplete
                id="tipo"
                options={tipos}
                value={formik.values.tipo}
                label="Tipo de cliente"
                formik={formik}
                getOptionLabel={(option) =>
                  CapSentence(option?.descripcion || null)
                }
                isOptionEqualToValue={(option, value) =>
                  option.codigo === value.codigo
                }
                renderOption={null}
                inputRef={inputRefs.tipo}
                nextRef={inputRefs.grupo_prepaga}
                setNullOnClear={true} // Do not set to null when cleared
                handleKeyDown={null}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <CustomAutoComplete
                id="grupos_prepaga_id"
                options={grupos}
                value={formik.values.grupos_prepaga_id}
                label="Grupo prepaga"
                formik={formik}
                getOptionLabel={(option) => CapSentence(option?.razon || null)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                inputRef={(el) => (inputRefs.grupo_prepaga.current = el)}
                setNullOnClear={true} // Do not set to null when cleared
                nextRef={null}
              />
            </Grid>
          </Grid>
        </Container>
        {saveLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={8}
          >
            <FacebookCircularProgress />
          </Box>
        )}
        {saveError && (
          <Box display="flex" justifyContent="center" alignItems="center" m={2}>
            <Alert variant="filled" severity="error">
              {saveError}
            </Alert>
          </Box>
        )}

        <Grid item xs={12}>
          <SaveCancelButtons
            handleCancel={handleClickVolver}
            // loading={loading}
          />
        </Grid>
      </form>
    </ProtectedRoute>
  );
};

export default InsertUpdateCliente;
