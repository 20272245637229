import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemText,
  Collapse,
  ListItemButton,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BuildIcon from "@mui/icons-material/Build";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import crypto from "./Crypto";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import ModalSystemInfo from "./modals/ModalSystemInfo";
import { useLocation } from "react-router-dom";

const RightDrawer = ({ open, toggleDrawer }) => {
  const [openPlanes, setOpenPlanes] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [openProcesses, setOpenProcesses] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handlePlanesClick = () => setOpenPlanes(!openPlanes);
  const handleReportsClick = () => setOpenReports(!openReports);
  const handleProcessesClick = () => setOpenProcesses(!openProcesses);
  const [modalSystemInfoOpen, setModalSystemInfoOpen] = useState(false);
  const history = useHistory();
  const location = useLocation(); // Usamos useLocation para detectar cambios de ruta

  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const handleReporteTurnosPorAgenda = () => {
    toggleDrawer(false)(); // Close the drawer
    history.push("/reportes", { component: "ReporteTurnosPorAgenda" }); // Navigate to "reportes" page
  };

  const handleReporteParteDiario = () => {
    toggleDrawer(false)(); // Close the drawer
    history.push("/reportes", { component: "ReporteParteDiario" }); // Navigate to "reportes" page
  };

  const handleReporteMovimientosCajas = () => {
    toggleDrawer(false)(); // Close the drawer
    history.push("/reportes", { component: "ReporteMovimientosCajas" }); // Navigate to "reportes" page
  };

  const handleReporteParteGuardia = () => {
    toggleDrawer(false)(); // Close the drawer
    history.push("/reportes", { component: "ReporteParteGuardia" }); // Navigate to "reportes" page
  };

  const handleReporteLiquidacionLaboratorios = () => {
    toggleDrawer(false)(); // Close the drawer
    history.push("/reportes", { component: "ReporteLiquidacionLaboratorios" }); // Navigate to "reportes" page
  };

  const handleRecibirTrabajosLaboratorios = () => {
    toggleDrawer(false)(); // Close the drawer
    history.push("/recibirtrabajoslaboratorios");
  };

  const handleListasPreciosForm = () => {
    toggleDrawer(false)(); // Close the drawer
    history.push("/listaspreciosform");
  };

  const handleOpenSystemInfo = () => {
    setModalSystemInfoOpen(true);
  };

  const hideModalSystemInfo = () => {
    setModalSystemInfoOpen(false);
  };

  const handleLogout = () => {
    // Obtener el último email guardado del localStorage (desencriptado)
    let lastLogged = crypto.decryptLocalStorage("user_email");

    // Limpiar sessionStorage y localStorage
    sessionStorage.clear();
    localStorage.clear();

    // Si existe un valor válido para lastLogged, lo encripta y lo guarda nuevamente
    if (
      typeof lastLogged !== "undefined" &&
      lastLogged !== null &&
      lastLogged.length > 0
    ) {
      crypto.encryptLocalStorage("user_email", lastLogged);
    }

    // Navegar a la página principal
    history.push("/");
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "#f4f4f9",
          width: 300,
        },
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={toggleDrawer(false)}
        sx={{ alignSelf: "flex-end", padding: 2 }}
      >
        <CloseIcon />
      </IconButton>

      <Divider />

      <List>
        {/* Planes y Precios */}
        <ListItemButton
          sx={{
            padding: "8px 16px", // Reduce padding inside items
            mb: 0.5, // Add a smaller margin-bottom to reduce space between items
          }}
          onClick={handlePlanesClick}
        >
          {openPlanes ? (
            <ExpandLess sx={{ color: "primary.main" }} />
          ) : (
            <ExpandMore sx={{ color: "drawericon" }} />
          )}
          <ListItemText
            sx={{
              marginLeft: 2,
              color: openPlanes ? "primary.main" : "drawertext",
            }}
            primary="Planes y Precios"
          />
          <AttachMoneyIcon
            sx={{ color: openPlanes ? "primary.main" : "drawericon" }}
          />
        </ListItemButton>
        <Collapse in={openPlanes} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={() => console.log("Planes clicked")}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Planes
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={handleListasPreciosForm}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Listas de Precios
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={() => console.log("Nomenclador clicked")}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Nomenclador
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>

        <Divider sx={{ my: 1 }} />

        {/* Reports */}
        <ListItemButton onClick={handleReportsClick}>
          {openReports ? (
            <ExpandLess sx={{ color: "primary.main" }} />
          ) : (
            <ExpandMore sx={{ color: "drawericon" }} />
          )}
          <ListItemText
            sx={{
              marginLeft: 2,
              color: openReports ? "primary.main" : "drawericon",
            }}
            primary="Reportes"
          />
          <AssessmentIcon
            sx={{ color: openReports ? "primary.main" : "drawericon" }}
          />
        </ListItemButton>
        <Collapse in={openReports} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={handleReporteTurnosPorAgenda}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Turnos asignados por agenda
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={handleReporteParteDiario}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Parte diario
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={handleReporteParteGuardia}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Parte de guardia
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={handleReporteMovimientosCajas}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Movimientos de cajas
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, padding: "4px 30px" }}
              onClick={handleReporteLiquidacionLaboratorios}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Liquidación de laboratorios
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>

        <Divider sx={{ my: 1 }} />

        {/* Processes */}
        <ListItemButton onClick={handleProcessesClick}>
          {openProcesses ? (
            <ExpandLess sx={{ color: "primary.main" }} />
          ) : (
            <ExpandMore sx={{ color: "drawericon" }} />
          )}
          <ListItemText
            sx={{
              marginLeft: 2,
              color: openProcesses ? "primary.main" : "drawericon",
            }}
            primary="Procesos"
          />
          <BuildIcon
            sx={{ color: openProcesses ? "primary.main" : "drawericon" }}
          />
        </ListItemButton>
        <Collapse in={openProcesses} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={handleRecibirTrabajosLaboratorios}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Recibir trabajos de laboratorios
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => console.log("Proceso 2 clicked")}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: "0.85rem", color: "drawertext" }}>
                    Proceso 2
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} />

        {/* Información del sistema */}
        <ListItemButton onClick={handleOpenSystemInfo}>
          <ListItemText
            sx={{ marginLeft: 2, color: "drawericon" }}
            primary="Información del sistema"
          />
          <InfoIcon sx={{ color: "drawericon" }} />
        </ListItemButton>

        <Divider sx={{ my: 1 }} />

        {/* Cerrar sesión */}
        <ListItemButton onClick={handleLogout}>
          <ListItemText
            sx={{ marginLeft: 2, color: "drawericon" }}
            primary="Cerrar sesión"
          />
          <LogoutIcon sx={{ color: "drawericon" }} />
        </ListItemButton>
      </List>
      {modalSystemInfoOpen && (
        <ModalSystemInfo
          showModal={modalSystemInfoOpen}
          hideModal={hideModalSystemInfo}
        />
      )}
    </Drawer>
  );
};

export default RightDrawer;
