import React, { useState, useContext, useEffect } from "react";
import "../../assetss/css/App.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import DayWeather from "../DayWeather";
import BoxButton from "../BoxButton";
import BoxDate from "../BoxDate";
import BoxWeather from "../BoxWeather";
import BoxDolar from "../BoxDolar";
import AnalogClock from "../AnalogClock";
import SFModalNota from "../SFModalNota";
import Sillon from "../../assetss/img/dentalchair.png";
import Llamado from "../../assetss/img/waitingroom.png";
import { useHistory } from "react-router-dom";
import TopBarContext from "../contexts/TopBarContext";
import ModalCarteleria from "../modals/ModaCarteleria";
import PieChartTurnosPorDia from "../charts/PieChartTurnosPorDia";
import CombinedChartPacientesCitados from "../charts/CombinedChartPacientesCitados";
import GenericCard from "../charts/GenericCard";
import PersonIcon from "@mui/icons-material/Person";
import HealingIcon from "@mui/icons-material/Healing";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

export default function Home() {
  const history = useHistory();
  const { changeButton } = useContext(TopBarContext);
  const [modalCarteleriaOpen, setModalCarteleriaOpen] = useState(false);

  useEffect(() => {
    changeButton("Home");
  }, []);

  function formatNumber(num) {
    if (Math.abs(num) >= 1e6) {
      return (num / 1e6).toFixed(1) + "M"; // Millones
    } else if (Math.abs(num) >= 1e3) {
      return (num / 1e3).toFixed(1) + "K"; // Miles
    }
    return num.toString(); // Menos de mil
  }

  function handleAgendas() {
    history.push({
      pathname: "/consultorios",
    });
  }

  function handleSalaESpera() {
    setModalCarteleriaOpen(true);
  }

  const hideModalCarteleria = () => {
    setModalCarteleriaOpen(false);
  };

  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "center",
          borderBottom: 1,
          borderColor: "#94a3b8",
          paddingBottom: 2,
          marginTop: 4,
        }}
      >
        <BoxDate />
        <BoxWeather />
        <AnalogClock />
        <BoxDolar tipo={"oficial"} />
        <BoxDolar tipo={"blue"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          gap: { xs: 0.5, sm: 1.5 },
          justifyContent: { xs: "center", lg: "center" },
          borderBottom: 1,
          borderColor: "#94a3b8",
          paddingBottom: 1,
          marginTop: -3,
        }}
      >
        <BoxButton
          handleClick={handleAgendas}
          text={"Consultorios"}
          image={Sillon}
        />
        <BoxButton
          handleClick={handleSalaESpera}
          text={"Llamador"}
          image={Llamado}
        />
      </Box>

      {modalCarteleriaOpen && (
        <ModalCarteleria
          showModal={modalCarteleriaOpen}
          hideModal={hideModalCarteleria}
        />
      )}
    </Container>
  );
}
