import { Clock } from "react-clock-styled";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
function AnalogClock() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "12rem",
        height: "10rem",
        color: "white",
        bgcolor: "#8D6E63",
        border: 1,
        borderRadius: 2,
        borderColor: "#8D6E63",
        marginTop: 0,
      }}
    >
      <Box sx={{ marginLeft: 1.5, marginTop: 0.5 }}>
        <Clock
          size="sm"
          border="2px dotted"
          color="#475569"
          handleColor="#1976d2"
          bg="#F5F5F5"
        />
      </Box>
    </Box>
  );
}

export default AnalogClock;
