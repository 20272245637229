/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import crypto from "../Crypto";
import { ModeloPost, ModeloUpdate } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Draggable, { DraggableCore } from "react-draggable";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import PaidIcon from "@mui/icons-material/Paid";
import { ModeloGetUrlJson } from "../ModeloCrud";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Avatar from "@mui/material/Avatar";
import { formatNumberES, CapSentence } from "../utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "../DeleteModel";
import InserPresupuestoFormaPago from "../presupuestos/InsertPresupuestoFormaPago";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <PaidIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />

      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = yup.object({
  forma_pago: yup
    .string()
    .min(4, "El campo requiere al menos 3 caracteres.")
    .required("Campo obligatorio."),
});

export default function ModalFormaPago({
  row,
  allPagos,
  showModal,
  hideModal,
  buscarPresupuestos,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const Today = new Date();
  const [errorSave, setErrorSave] = useState(null);
  const [deletePagoId, setDeletePagoId] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("1");

  useEffect(() => {
    //buscarPagos();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNew = () => {};

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <TableRow
          sx={{ height: "1.5rem", borderBottom: 3, borderColor: "white" }}
        >
          <TableCell>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                fontSize: 18,
                fontWeight: "bold",
                bgcolor: row.tipo === "FP" ? "icongreen" : "iconred",
              }}
            >
              {row.tipo === "FP" ? "E" : "C"}
            </Avatar>
          </TableCell>
          <TableCell>{row.fecha_arg}</TableCell>
          <TableCell>{CapSentence(row?.usuario)}</TableCell>
          <TableCell align="right" sx={{}}>
            {row.importe}
          </TableCell>
          {row.tipo === "FP" && (
            <>
              <TableCell align="left">
                <DeleteIcon
                  cursor="pointer"
                  sx={{
                    color: "primary.red",
                    fontSize: 24,
                  }}
                  onClick={() => setDeletePagoId(row.id)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
      </React.Fragment>
    );
  }

  async function buscarPagos() {
    setLoading(true);
    let result = await ModeloGetUrlJson(
      "presupuesto",
      row.id,
      "vista-forma-pago",
      null
    );
    setLoading(false);

    if (result.error === false) {
    } else {
    }
  }

  const handleClose = () => {
    hideModal();
  };

  const formik = useFormik({
    initialValues: {
      forma_pago: row.forma_pago,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      grabar(values);
    },
  });

  async function grabar(values) {
    setLoading(true);
    setErrorSave(null);
    const Data = {
      forma_pago: values.forma_pago,
    };
    let result = await ModeloUpdate("presupuesto", row.id, Data);
    setLoading(false);
    if (result.error === false) {
      buscarPresupuestos();
    } else {
      setLoading(false);
      setModalMessage(result.errormessage);
      setModalInformationOpen(true);
    }
  }

  return (
    <React.Fragment>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          open={showModal}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
          maxWidth="sm"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
          PaperProps={{
            style: {
              minHeight: "80%",
              maxHeight: "80%",
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
            row={row}
          >
            Forma de Pago
          </BootstrapDialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent className="content">
              <DialogContentText>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="forma pago">
                      <Tab label="Notas" value="1" />
                      <Tab label="Pagos" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: -1,
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        color="primary"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        sx={{
                          backgroundColor: "primary.main",
                          ":hover": {
                            bgcolor: "#475569", // theme.palette.primary.main
                            color: "white",
                          },
                          textTransform: "capitalize",
                        }}
                      >
                        <span>Grabar</span>
                      </LoadingButton>
                    </Box>

                    <TextField
                      id="forma_pago"
                      label="Forma de Pago"
                      multiline
                      rows={12}
                      value={formik.values.forma_pago}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.forma_pago
                          ? formik.errors.forma_pago
                          : ""
                      }
                      error={
                        formik.touched.forma_pago &&
                        Boolean(formik.errors.forma_pago)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: -1,
                      }}
                    >
                      <Box sx={{ marginLeft: -3, marginBottom: 1 }}>
                        <InserPresupuestoFormaPago
                          row={row}
                          buscarPresupuestos={buscarPresupuestos}
                        />
                      </Box>
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 250 }}
                            aria-label="customized table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{ bgcolor: "avatar", color: "white" }}
                                >
                                  Tipo
                                </TableCell>
                                <TableCell
                                  sx={{ bgcolor: "avatar", color: "white" }}
                                >
                                  Fecha
                                </TableCell>
                                <TableCell
                                  sx={{ bgcolor: "avatar", color: "white" }}
                                >
                                  Usuario
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ bgcolor: "avatar", color: "white" }}
                                >
                                  Importe
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ bgcolor: "avatar", color: "white" }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {!loading &&
                                allPagos?.length > 0 &&
                                allPagos
                                  .filter(
                                    (p) =>
                                      p.presupuesto_id === row.id &&
                                      p.tipo === "FP"
                                  )
                                  .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                                  .map((rowp) => (
                                    <Row key={rowp.id} row={rowp} />
                                  ))}
                              {allPagos?.length === 0 && (
                                <Box sx={{ ml: 2, mt: 2, mb: 2 }}> </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Paper
                        elevation={2}
                        sx={{
                          with: "90%",
                          height: "auto",
                          py: 0.75,
                          mb: 3,
                          px: 1,
                          marginTop: 2,
                        }}
                      >
                        <div className="flex items-end justify-end mr-10">
                          <Typography variant="h6">
                            Total pagado en 25:
                          </Typography>

                          <Avatar
                            variant="rounded"
                            sx={{
                              border: "1px solid ",
                              color: "white",
                              bgcolor: "icongreen",
                              fontWeight: "bold",
                              fontSize: 14,
                              width: 105,
                              height: 30,
                              mr: -0.5,
                              ml: 2,
                            }}
                          >
                            {formatNumberES(
                              allPagos?.length > 0 &&
                                allPagos
                                  .filter(
                                    (p) =>
                                      p.presupuesto_id === row.id &&
                                      p.tipo === "FP"
                                  )
                                  .reduce(
                                    (sum, pago) =>
                                      sum + parseFloat(pago.importe),
                                    0
                                  ),
                              2
                            )}
                          </Avatar>
                        </div>
                      </Paper>
                    </Box>
                  </TabPanel>
                </TabContext>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="actions"></DialogActions>
            {deletePagoId !== null && (
              <DeleteModel
                model={"presupuesto-forma-pago"}
                modelid={deletePagoId}
                okmethod={buscarPresupuestos}
                nullmethod={setDeletePagoId}
              />
            )}
          </form>
        </BootstrapDialog>
      </Draggable>
    </React.Fragment>
  );
}
