import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import loadingGif from "../assetss/img/loading_64.gif";
import BorrarImagen from "./BorrarImagen";
import ModalInformation from "./modals/ModalInformation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { CardContent } from "@mui/material";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import FacebookCircularProgress from "./FacebookCircularProgress";
import { ModeloGetUrl } from "./ModeloCrud";
import RenderCustomTable from "./RenderCustomTable";
import ModalPlanPrepaga from "./modals/ModalPlanPrepaga";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { hasPermission } from "./utils/Utils";
import crypto from "./Crypto";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "./DeleteModel";
import { useHistory } from "react-router-dom";

export default function ClientesPlanes({
  currentCliente,
  allPlanes,
  busqueda,
}) {
  const [loadingRx, setLoadingRx] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [editarPlanPrepaga, setEditarPlanPrepaga] = useState(false);
  const [insertarPlanPrepaga, setInsertarPlanPrepaga] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [deletePlanId, setDeletePlanId] = useState(null);
  const history = useHistory();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const columns = [
    { field: "codigo_nomenclador", headerName: "Código", isCodigoPrec: true },
    { field: "precio", headerName: "Precio", isPrecio: true },
    {
      field: "descripcion_nomenclador",
      headerName: "Descripción",
      capitalize: true,
    },
    { field: "grupo_dental", headerName: "Piezas", capitalize: true },
    { field: "codigo_lista_precios", headerName: "Lista" },
    { field: "observaciones", headerName: "Notas" },
  ];
  const actions = [
    {
      icon: EditIcon,
      //handle: (row) => handleEdit(row),
      sx: { color: "primary.main" },
      requiredPermission: "ALTA CPREPAGAS",
    },
    {
      icon: DeleteIcon,
      //handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
      requiredPermission: "ALTA CPREPAGAS",
    },
  ];

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };

  useEffect(() => {}, []);

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  const hidePlanPrepaga = () => {
    setEditarPlanPrepaga(false);
    setInsertarPlanPrepaga(false);
  };
  const handleEdit = (row) => {
    setSelectedPlan(row);
    setEditarPlanPrepaga(true);
  };
  const handleNew = () => {
    setInsertarPlanPrepaga(true);
  };

  function handleListasButton(listaId) {
    history.push({
      pathname: "/listaspreciosform",
      state: { listaId }, // Pasa el `listaId` en el estado
    });
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCellPrecio = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#64748b",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [allLista, setAllLista] = useState(null);
    let [filterLista, setFilterLista] = useState([]);
    const [filterText, setFilterText] = useState("");
    const tableRef = useRef();

    function onChangeSearch(e) {
      const filterText = e.target.value;
      setFilterText(filterText);
      FiltrarLista(filterText);
    }

    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        FiltrarLista(filterText);
      }
    }

    function QuitarFiltro() {
      setFilterText("");
      filterLista = allLista.sort((a, b) =>
        a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1
      );
      let tempData = [...filterLista];
      setFilterLista(tempData);
    }

    function FiltrarLista(pFiltro) {
      if (pFiltro.length > 0) {
        filterLista = allLista
          .filter(
            (g) =>
              g.codigo_nomenclador.indexOf(pFiltro) >= 0 ||
              g.descripcion_nomenclador
                .toLowerCase()
                .indexOf(pFiltro.toLowerCase()) >= 0
          )
          .sort((a, b) =>
            a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1
          );
        if (filterLista.length <= 0) {
          filterLista = [];
        }
      } else {
        filterLista = allLista.sort((a, b) =>
          a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1
        );
      }
      let tempData = [...filterLista];
      setFilterLista(tempData);
    }

    function openCollapse(isOpen, planId) {
      setOpen(!isOpen);
      if (!isOpen === true) {
        buscarLPrecios(planId);
      } else {
        filterLista = [];
        let tempData = [...filterLista];
        setFilterLista(tempData);
      }
    }
    async function buscarLPrecios(planId) {
      setLoading(true);
      const result = await ModeloGetUrl(
        "plan-prepaga",
        planId,
        "precios-nomenclador",
        null
      );
      setLoading(false);
      console.log(result);
      if (result.error === false) {
        if (result.listado.length > 0) {
          const allData = result.listado.sort((a, b) =>
            a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1
          );

          setAllLista(allData);
          setFilterLista(allData);
          //
        }
      } else {
      }
    }

    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell align="left">
            <div className="flex justify-between">
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => openCollapse(open, row.id)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
              <div>
                {row.habilitado && currentCliente.fecha_baja === null ? (
                  <CheckCircleIcon
                    sx={{
                      color: "primary.green",
                      fontSize: 24,
                      mr: { xs: 1, sm: 2 },
                      ml: { xs: -3, sm: -2 },
                      marginTop: 0.5,
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "primary.red",
                      fontSize: 24,
                      mr: { xs: 1, sm: 2 },
                      ml: { xs: -3, sm: -2 },
                      marginTop: 0.5,
                    }}
                  />
                )}
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell align="left" component="th" scope="row">
            <Box
              sx={{
                textTransform: "capitalize",
                color:
                  row.habilitado && currentCliente.fecha_baja === null
                    ? "#123564"
                    : "#cbd5e1",
              }}
            >
              {row.codigo.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Box
              sx={{
                textTransform: "capitalize",
                color:
                  row.habilitado && currentCliente.fecha_baja === null
                    ? "#123564"
                    : "#cbd5e1",
              }}
            >
              {row.descripcion.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Box
              sx={{
                textTransform: "capitalize",
                color:
                  row.habilitado && currentCliente.fecha_baja === null
                    ? "#123564"
                    : "#cbd5e1",
              }}
            >
              {row.cobertura_codigo.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Box
              sx={{
                textTransform: "capitalize",
                color:
                  row.habilitado && currentCliente.fecha_baja === null
                    ? "#123564"
                    : "#cbd5e1",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => handleListasButton(row.lista_precios_id)}
                sx={{
                  textTransform: "capitalize",
                  width: 120,
                  color:
                    row.habilitado && currentCliente.fecha_baja === null
                      ? "primary.main"
                      : "#cbd5e1",
                }}
              >
                {row.lista_precios.toLowerCase()}
              </Button>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Box
              sx={{
                textTransform: "capitalize",
                color:
                  row.habilitado && currentCliente.fecha_baja === null
                    ? "#123564"
                    : "#cbd5e1",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => handleListasButton(row.lista_basica_id)}
                sx={{
                  textTransform: "capitalize",
                  width: 120,
                  color:
                    row.habilitado && currentCliente.fecha_baja === null
                      ? "primary.main"
                      : "#cbd5e1",
                }}
              >
                {row.lista_basica.toLowerCase()}
              </Button>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">
            {hasPermission(userRoles, "ALTA CPLANESPREPAGA") && (
              <EditIcon
                cursor="pointer"
                sx={{
                  color: "primary.main",
                  fontSize: 24,
                }}
                onClick={() => handleEdit(row)}
              />
            )}
          </StyledTableCell>
          <StyledTableCell align="left">
            {hasPermission(userRoles, "BAJA CPLANESPREPAGA") && (
              <DeleteIcon
                cursor="pointer"
                sx={{
                  color: "primary.red",
                  fontSize: 24,
                }}
                onClick={() => setDeletePlanId(row.id)}
              />
            )}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ width: "90%", marginTop: 2, marginBottom: 2 }}>
                <Box
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {loading && <FacebookCircularProgress />}
                  {!loading && (
                    <Box
                      sx={{
                        margin: 2,
                        width: 1,
                      }}
                    >
                      <Typography variant="body2">
                        {row.observaciones}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <RenderCustomTable
                data={
                  filterLista !== null &&
                  filterLista.length > 0 &&
                  filterLista.sort((a, b) => (a.orden > b.orden ? 1 : -1))
                }
                columns={columns}
                paddingLeft={0}
                paddingRight={0}
                actions={null}
                showSearch={true}
              />
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      {loadingRx && (
        <div className="ml-2 mt-0">
          <img alt="Loading" src={loadingGif} />
        </div>
      )}
      <Card
        sx={{
          ml: -2,
          mr: -2,
          backgroundColor: "bgcards",
          marginBottom: 4,
          height: "100%",
        }}
      >
        <CardHeader
          avatar={<PriceChangeIcon sx={{ color: "avatar", fontSize: 48 }} />}
          title={
            <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Planes
              </Typography>
              {hasPermission(
                userRoles,
                "ALTA CCENTROSODONTOLOGOSESPECIALIDADES"
              ) && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
            </Box>
          }
          subheader={<></>}
        />
        <CardContent>
          <Box sx={{ mb: 4, marginTop: -2 }}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Habilitado</StyledTableCell>
                    <StyledTableCell align="left">Código</StyledTableCell>
                    <StyledTableCell align="left">Descripción</StyledTableCell>
                    <StyledTableCell align="left">Plan</StyledTableCell>
                    <StyledTableCell align="left">L.precios</StyledTableCell>
                    <StyledTableCell align="left">L.básica</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allPlanes !== null &&
                    allPlanes.length > 0 &&
                    allPlanes
                      .sort((a, b) => (a.habilitado > b.habilitado ? -1 : 1))
                      .map((row) => <Row key={row.id} row={row} />)}
                  {allPlanes !== null && allPlanes.length === 0 && (
                    <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                      {" "}
                      <Typography variant="body1" gutterBottom>
                        Sin Planes.
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalSuccessOpen && modalMessage !== null}
        hideModal={hideSuccessModal}
        message={modalMessage}
        tipo="success"
      />
      {deleteImageId !== null && (
        <BorrarImagen
          imageId={deleteImageId}
          setLoading={setLoading}
          //   afterMethod={() => metodorefresh()}
          setDeleteImageId={setDeleteImageId}
        />
      )}
      {editarPlanPrepaga && (
        <ModalPlanPrepaga
          row={selectedPlan}
          showModal={editarPlanPrepaga}
          hideModal={hidePlanPrepaga}
          currentCliente={currentCliente}
          busqueda={busqueda}
        />
      )}
      {deletePlanId !== null && (
        <DeleteModel
          model={"plan-prepaga"}
          modelid={deletePlanId}
          okmethod={busqueda}
          nullmethod={setDeletePlanId}
        />
      )}
      {insertarPlanPrepaga && (
        <ModalPlanPrepaga
          row={null}
          showModal={insertarPlanPrepaga}
          hideModal={hidePlanPrepaga}
          currentCliente={currentCliente}
          busqueda={busqueda}
        />
      )}
    </>
  );
}
