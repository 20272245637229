import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikCustomTextField from "../FormikCustomTextField"; // Asegúrate de tener este componente
import FormikCustomAutoComplete from "../FormikCustomAutoComplete"; // Asegúrate de tener este componente
import {
  FormControlLabel,
  Switch,
  Grid,
  Divider,
  Box,
  Alert,
} from "@mui/material";
import CustomModal from "./CustomModal";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SaveCancelButtons from "../SaveCancelButtons";
import crypto from "../Crypto";
import { CapSentenceArray, CapSentence } from "../utils/Utils";
import { ModeloPost, ModeloPut, ModeloUpdate } from "../ModeloCrud";

// Validaciones con Yup
const validationSchema = Yup.object({
  nomenclador_id: Yup.object().required("Campo obligatorio"),
  precio: Yup.number()
    .required("Precio es requerido")
    .min(0, "Ingrese un número positivo"),
  grupos_dentales_id: Yup.object().required("Campo obligatorio"),
  edad_coseguro_hasta: Yup.number()
    .nullable()
    .min(0, "Edad debe ser un número positivo")
    .max(120, "Edad debe ser menor a 120")
    .test(
      "es-mayor-que-edad-desde",
      "No puede ser menor a edad desde",
      function (value) {
        const { edad_coseguro_desde } = this.parent;
        if (edad_coseguro_desde != null && value != null) {
          return value >= edad_coseguro_desde;
        }
        return true; // Si alguno es null, no aplicar la validación.
      }
    ),
  precio_fuera_rango: Yup.number()
    .nullable()
    .min(0, "El precio debe ser un número positivo"),
  precio_fuera_cobertura: Yup.number()
    .nullable()
    .min(0, "El precio debe ser un número positivo"),
  requiere_autorizacion: Yup.boolean(),
  requiere_odontograma: Yup.boolean(),
  requiere_planilla_aparte: Yup.boolean(),
  observaciones: Yup.string(),
});

const ModalNomencladorPreciosLista = ({
  row,
  open,
  handleClose,
  listaId,
  setRows,
}) => {
  // Referencias para los campos
  const [showAlert, setShowAlert] = useState(false);
  const gruposDentales = crypto.decryptDataStorage("grupos_dentales");
  const piezasDentales = crypto.decryptDataStorage("piezas_dentales");

  const nomencladores = CapSentenceArray(
    crypto.decryptDataStorage("nomencladores"),
    ["codigo_descripcion"]
  );
  const inputRefs = {
    nomenclador_id: useRef(null),
    precio: useRef(null),
    grupos_dentales_id: useRef(null),
    edad_coseguro_desde: useRef(null),
    edad_coseguro_hasta: useRef(null),
    precio_fuera_rango: useRef(null),
    precio_fuera_cobertura: useRef(null),
    requiere_autorizacion: useRef(null),
    requiere_odontograma: useRef(null),
    requiere_planilla_aparte: useRef(null),
    observaciones: useRef(null),
  };

  const [initialNomenclador, setInitialNomenclador] = useState(null);
  const [initialGrupoDental, setInitialGrupoDental] = useState(null);
  const [saveError, setSaveError] = useState(null);
  useEffect(() => {
    console.log(row);
    if (row !== null) {
      const selNomenclador = nomencladores.filter(
        (n) => Number(n.nomenclador_id) === Number(row.nomenclador_id)
      );
      if (selNomenclador.length > 0) {
        setInitialNomenclador(selNomenclador[0]);
      }
      const selGrupo = gruposDentales.filter(
        (g) => Number(g.id) === Number(row.grupos_dentales_id)
      );
      if (selGrupo.length > 0) {
        setInitialGrupoDental(selGrupo[0]);
      }
    }
  }, []);

  const initialValues = {
    nomenclador_id: initialNomenclador,
    precio: row?.precio || "",
    grupos_dentales_id: initialGrupoDental,
    edad_coseguro_desde: row?.edad_coseguro_desde || "",
    edad_coseguro_hasta: row?.edad_coseguro_hasta || "",
    precio_fuera_rango:
      row?.precio_fuera_rango === "0.00" ? null : row?.precio_fuera_rango || "",
    precio_fuera_cobertura:
      row?.precio_fuera_cobertura === "0.00"
        ? null
        : row?.precio_fuera_cobertura || "",
    requiere_autorizacion: row?.requiere_autorizacion || false,
    requiere_odontograma: row?.requiere_odontograma || false,
    requiere_planilla_aparte: row?.requiere_planilla_aparte || false,
    observaciones: row?.observaciones || "",
  };

  const handleRowUpdate = (updatedRow) => {
    // Encontramos el nomenclador correspondiente si se cambia el nomenclador_id
    const nomenclador = nomencladores.find(
      (n) => n.nomenclador_id === updatedRow.nomenclador_id
    );
    const grupoDental = gruposDentales.find(
      (g) => g.id === updatedRow.grupos_dentales_id
    );

    setRows((prevRows) => {
      // Actualizamos la fila y luego ordenamos las filas por codigo_nomenclador
      const updatedRows = prevRows.map((row) =>
        row.id === updatedRow.id
          ? {
              ...row, // Copiamos todos los campos actuales de la fila
              ...updatedRow, // Actualizamos los campos que vienen del backend
              ...(nomenclador && {
                // Si se encontró el nomenclador, actualizamos código y descripción
                codigo_nomenclador: nomenclador.codigo.replace(/:/g, "."),
                descripcion_nomenclador: nomenclador.descripcion,
                tasaiva: nomenclador.tasaiva,
              }),
              ...(grupoDental && {
                // Si se encontró el grupoDental, actualizamos descripción
                grupo_dental: grupoDental.descripcion,
              }),
            }
          : row
      );

      // Ordenamos las filas por codigo_nomenclador después de actualizar
      return updatedRows.sort((a, b) => {
        const codigoA = a.codigo_nomenclador.toLowerCase();
        const codigoB = b.codigo_nomenclador.toLowerCase();

        if (codigoA < codigoB) return -1;
        if (codigoA > codigoB) return 1;
        return 0;
      });
    });

    console.log(updatedRow);
  };

  const handleAddRow = (newRow) => {
    // Encontramos el nomenclador correspondiente si el nuevo row incluye nomenclador_id
    const nomenclador = nomencladores.find(
      (n) => n.nomenclador_id === newRow.nomenclador_id
    );

    // Encontramos el grupoDental correspondiente si el nuevo row incluye grupo_dental_id
    const grupoDental = gruposDentales.find(
      (g) => g.id === newRow.grupos_dentales_id
    );

    // Si se encontró el nomenclador, agregamos codigo y descripcion al newRow
    const newRowWithNomenclador = nomenclador
      ? {
          ...newRow,
          codigo_nomenclador: nomenclador.codigo.replace(/:/g, "."),
          descripcion_nomenclador: nomenclador.descripcion,
          tasaiva: nomenclador.tasaiva,
        }
      : newRow;

    // Si se encontró el grupoDental, agregamos su descripcion al newRow
    const newRowWithGrupoDental = grupoDental
      ? {
          ...newRowWithNomenclador, // Mantenemos las modificaciones del nomenclador
          grupo_dental: grupoDental.descripcion,
        }
      : newRowWithNomenclador;

    setRows((prevRows) =>
      // Agregamos la nueva fila y luego ordenamos por 'codigo'
      [...prevRows, newRowWithGrupoDental].sort((a, b) => {
        const codigoA = a.codigo_nomenclador.toLowerCase();
        const codigoB = b.codigo_nomenclador.toLowerCase();

        // Ordena por 'codigo' alfabéticamente
        if (codigoA < codigoB) return -1;
        if (codigoA > codigoB) return 1;

        // Si 'codigo' es igual, ordena por 'nomenclador_id'
        return a.nomenclador_id - b.nomenclador_id;
      })
    );
  };

  async function handleSubmit(values) {
    setSaveError(null);
    const Data = {
      listas_precios_id: listaId,
      nomenclador_id: values.nomenclador_id.nomenclador_id,
      precio: values.precio,
      requiere_autorizacion: values.requiere_autorizacion ? 1 : 0,
      requiere_odontograma: values.requiere_odontograma ? 1 : 0,
      requiere_planilla_aparte: values.requiere_planilla_aparte ? 1 : 0,
      observaciones: values.observaciones,
      precio_fuera_rango: Number(values.precio_fuera_rango),
      edad_coseguro_desde:
        values.edad_coseguro_desde === ""
          ? 0
          : Number(values.edad_coseguro_desde),
      edad_coseguro_hasta:
        values.edad_coseguro_hasta === ""
          ? 0
          : Number(values.edad_coseguro_hasta),
      grupos_dentales_id: values.grupos_dentales_id.id,
      habilitado: values.habilitado ? 1 : 0,
      precio_fuera_cobertura: Number(values.precio_fuera_cobertura),
    };
    let result = null;
    if (!row) {
      result = await ModeloPost("listas-precios-nomenclador", null, null, Data);
    } else {
      result = await ModeloUpdate("listas-precios-nomenclador", row.id, Data);
    }
    console.log(result);
    if (result.error === false) {
      if (!row) {
        handleAddRow(result.listado[0]);
      } else {
        handleRowUpdate(result.listado[0]);
      }
      handleClose();
    } else {
      if (Array.isArray(result.errormessage)) {
        setSaveError(result.errormessage[0].error);
      } else if (typeof result.errormessage === "string") {
        setSaveError(result.errormessage);
      } else {
        setSaveError("Error desconocido !");
      }
    }
  }

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Editar precio"
      loading={null}
      disableActions
      width="60%"
      maxWidth="900px"
      icon={
        row ? (
          <EditIcon fontSize="medium" sx={{ color: "white" }} />
        ) : (
          <AddIcon fontSize="medium" sx={{ color: "white" }} />
        )
      }
    >
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          noValidate
          enableReinitialize
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormikCustomAutoComplete
                    id="nomenclador_id"
                    options={nomencladores}
                    value={values.nomenclador_id}
                    label="Código"
                    getOptionLabel={(option) =>
                      option
                        ? (option.codigo ?? "") +
                          " " +
                          (option.descripcion
                            ? CapSentence(option.descripcion)
                            : "")
                        : null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.nomenclador_id === value.nomenclador_id
                    }
                    inputRef={inputRefs.grupos_dentales_id}
                    nextRef={inputRefs.edad_coseguro_desde}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <FormikCustomTextField
                    name="precio"
                    label="Precio"
                    type="number"
                    inputRef={inputRefs.precio}
                    nextInputRef={inputRefs.grupos_dentales_id}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <FormikCustomAutoComplete
                    id="grupos_dentales_id"
                    options={gruposDentales}
                    value={values.grupos_dentales_id}
                    label="Grupo dental"
                    getOptionLabel={(option) => option.descripcion}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    inputRef={inputRefs.grupos_dentales_id}
                    nextRef={inputRefs.edad_coseguro_desde}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormikCustomTextField
                    name="edad_coseguro_desde"
                    label="Edad Coseguro Desde"
                    type="number"
                    inputRef={inputRefs.edad_coseguro_desde}
                    nextInputRef={inputRefs.edad_coseguro_hasta}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormikCustomTextField
                    name="edad_coseguro_hasta"
                    label="Edad Coseguro Hasta"
                    type="number"
                    inputRef={inputRefs.edad_coseguro_hasta}
                    nextInputRef={inputRefs.precio_fuera_rango}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormikCustomTextField
                    name="precio_fuera_rango"
                    label="Precio Fuera de Rango"
                    type="number"
                    inputRef={inputRefs.precio_fuera_rango}
                    nextInputRef={inputRefs.precio_fuera_cobertura}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormikCustomTextField
                    name="precio_fuera_cobertura"
                    label="Precio Fuera de Cobertura"
                    type="number"
                    inputRef={inputRefs.precio_fuera_cobertura}
                    nextInputRef={inputRefs.observaciones}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.requiere_autorizacion}
                        onChange={(e) =>
                          setFieldValue(
                            "requiere_autorizacion",
                            e.target.checked
                          )
                        }
                        inputRef={inputRefs.requiere_autorizacion}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            inputRefs.requiere_odontograma.current?.focus();
                          }
                        }}
                      />
                    }
                    label="Requiere Autorización"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.requiere_odontograma}
                        onChange={(e) =>
                          setFieldValue(
                            "requiere_odontograma",
                            e.target.checked
                          )
                        }
                        inputRef={inputRefs.requiere_odontograma}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            inputRefs.requiere_planilla_aparte.current?.focus();
                          }
                        }}
                      />
                    }
                    label="Requiere Odontograma"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.requiere_planilla_aparte}
                        onChange={(e) =>
                          setFieldValue(
                            "requiere_planilla_aparte",
                            e.target.checked
                          )
                        }
                        inputRef={inputRefs.requiere_planilla_aparte}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            inputRefs.observaciones.current?.focus();
                          }
                        }}
                      />
                    }
                    label="Requiere Planilla Aparte"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormikCustomTextField
                    name="observaciones"
                    label="Observaciones"
                    multiline
                    rows={4}
                    inputRef={inputRefs.observaciones}
                  />
                </Grid>

                <Grid item xs={12}>
                  {showAlert?.length > 0 && (
                    <Alert variant="filled" severity="error">
                      {showAlert}
                    </Alert>
                  )}
                  {saveError !== null && (
                    <Alert variant="filled" severity="error">
                      {saveError}
                    </Alert>
                  )}
                  <Divider />
                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <SaveCancelButtons
                      handleCancel={handleClose}
                      loading={isSubmitting}
                      isModal={true}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomModal>
  );
};

export default ModalNomencladorPreciosLista;
