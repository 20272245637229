import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ApiService from "../apis/ApiService";
import NearMeIcon from "@mui/icons-material/NearMe";

export default function BoxDolar({ tipo }) {
  const [loading, setLoading] = useState(false);
  const [compra, setCompra] = useState(0);
  const [venta, setVenta] = useState(0);

  useEffect(() => {
    GetDolar();
  }, []);

  async function GetDolar() {
    try {
      setLoading(true);
      const urlDolar = "https://dolarapi.com/v1/dolares/" + tipo;
      let resultDolar = await ApiService.GetSimpleUrl(urlDolar, null);
      setLoading(false);
      if (typeof resultDolar !== "undefined") {
        setCompra(resultDolar.compra);
        setVenta(resultDolar.venta);
      }
    } catch (error) {
      alert("Error ! " + error.message);
    }
    return;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "12rem",
        height: "10rem",
        color: "white",
        bgcolor: "#66BB6A",
        p: 1,
        border: 1,
        borderRadius: 2,
        borderColor: "#66BB6A",
        marginTop: 0,
      }}
    >
      {!loading && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 1,
              marginTop: 1,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  textAlign: "start",
                  marginRight: 1,
                  marginTop: 0.5,
                  fontFamily: "Roboto",
                  fontSize: "1.25rem",
                  textTransform: "capitalize",
                }}
              >
                {tipo === "oficial" ? "Dolar Oficial BNA" : "Dolar Blue"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  marginTop: 2,
                  color: "white",
                  fontFamily: "Roboto",
                  fontSize: "1.2rem",
                }}
              >
                {`Compra: ${compra}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  marginTop: 2,
                  color: "white",
                  fontFamily: "Roboto",
                  fontSize: "1.2rem",
                }}
              >
                {`Venta: ${venta}`}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
