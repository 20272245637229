import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import * as moment from "moment"; // Para manejar fechas

// Registrar los componentes de Chart.js
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const CombinedChartPacientesCitados = () => {
  const [selectedClinica, setSelectedClinica] = useState(null); // Clínica seleccionada
  const [filteredData, setFilteredData] = useState([]); // Datos filtrados por clínica
  const [chartData, setChartData] = useState(null); // Datos del gráfico
  const today = moment().format("YYYY-MM-DD"); // Fecha de hoy

  // Generar datos de prueba para 30 días de septiembre para la Clínica 1
  const generateTestData = (startDate, endDate) => {
    const data = [];
    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      data.push({
        clinicaId: 1,
        clinica: "Clinica A",
        fecha: currentDate.format("YYYY-MM-DD"), // Fecha en formato ISO
        pacientesCitados: Math.floor(Math.random() * 30) + 5, // Número aleatorio de pacientes entre 5 y 30
      });
      currentDate.add(1, "days");
    }

    return data;
  };

  // Datos de ejemplo: 30 días de septiembre de 2024 para Clínica 1
  const data = generateTestData("2024-09-01", "2024-09-30");

  // Obtener las clínicas únicas del data
  const clinicas = [
    ...new Map(
      data.map((item) => [
        item.clinicaId,
        { id: item.clinicaId, nombre: item.clinica },
      ])
    ).values(),
  ];

  useEffect(() => {
    // Filtrar los datos según la clínica seleccionada
    if (selectedClinica) {
      const filtered = data.filter(
        (item) => item.clinicaId === selectedClinica.id
      );
      setFilteredData(filtered);

      // Crear datos para el gráfico
      const chartData = {
        labels: filtered.map((item) => moment(item.fecha).format("DD/MM/YYYY")), // Convertir fecha a formato dd/mm/yyyy
        datasets: [
          {
            type: "line",
            label: "Pacientes Citados (Línea)",
            data: filtered.map((item) => item.pacientesCitados),
            borderColor: "#36A2EB",
            fill: false,
            tension: 0.4, // Líneas curvas
          },
          {
            type: "bar",
            label: "Pacientes Citados (Barras)",
            data: filtered.map((item) => item.pacientesCitados),
            backgroundColor: filtered.map((item) => {
              if (moment(item.fecha).isSame(today, "day")) {
                return "rgba(75, 192, 192, 0.6)"; // Color verdozo para la fecha actual
              } else if (moment(item.fecha).isAfter(today)) {
                return "rgba(255, 99, 132, 0.2)"; // Color semitransparente para fechas futuras
              } else {
                return "#FF6384"; // Color normal para fechas pasadas
              }
            }),
            borderColor: filtered.map((item) => {
              if (moment(item.fecha).isSame(today, "day")) {
                return "#4BC0C0"; // Borde verdozo para la fecha actual
              } else if (moment(item.fecha).isAfter(today)) {
                return "#FF6384"; // Borde para fechas futuras
              } else {
                return "#FF6384"; // Borde normal para fechas pasadas
              }
            }),
            borderWidth: filtered.map(
              (item) =>
                moment(item.fecha).isAfter(today) ||
                moment(item.fecha).isSame(today, "day")
                  ? 2
                  : 0 // Borde para fechas futuras y la actual
            ),
          },
        ],
      };

      setChartData(chartData);
    }
  }, [selectedClinica]);

  return (
    <Box>
      {/* Autocompletar para seleccionar la clínica */}
      <Autocomplete
        options={clinicas}
        getOptionLabel={(option) => option.nombre}
        onChange={(event, newValue) => setSelectedClinica(newValue)} // Actualizar la clínica seleccionada
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar Clínica"
            variant="outlined"
            fullWidth
          />
        )}
      />

      {/* Gráfico combinado de líneas curvas y barras */}
      {chartData && (
        <div style={{ width: "1000px", height: "800px", marginTop: "20px" }}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: `Pacientes Citados - ${selectedClinica?.nombre}`,
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Fecha",
                  },
                  ticks: {
                    maxRotation: 90, // Rotación máxima
                    minRotation: 90, // Rotación mínima, forzando que las fechas queden verticales
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Pacientes Citados",
                  },
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default CombinedChartPacientesCitados;
